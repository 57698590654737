<template>

  <w3pp-button
    background="#1a5cff"
    class="edit"
    @click="open = true"
  >Edit</w3pp-button>

  <w3pp-modal
    :open="open"
    floatingClose
    @backgroundClick="open = false"
    @close="open = false"
  >
    <template #modal-header>
      <div class="modal-header">
        Update entry
      </div>
    </template>
    <template #default>
      <div class="form">
        <label class="input-field">
          <div class="label">Date</div>
          <input type="date" name="date" v-model="date">
        </label>
        <label class="input-field">
          <div class="label">Amount</div>
          <input type="number" v-model="amount" name="amount">
        </label>
        <div class="input-field">
          <div class="label">
            Tags
            <tagsSearch ref="tagSearch" @selectTag="updateTags" :tags="tags" :ledgerID="$route.params.id" />
          </div>
          <w3pp-slider class="slider" @click="openTagsSearch">
            <div
              v-for="(tag, i) in tags"
              :key="i"
              class="tag"
            >{{tag}}<w3ppClose @click="removeTag(tag)"/></div>
          </w3pp-slider>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-footer">
        <w3pp-button class="submit" @click="updateEntry" background="#015fcc">Submit</w3pp-button>
      </div>
    </template>
  </w3pp-modal>

</template>
<script>
import w3ppModal from '@/components/w3ppModal'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppClose from '@/components/w3ppClose'
import w3ppButton from '@/components/w3ppButton'
import tagsSearch from '@/components/tagsSearch'
import plusIcon from '@iconify-icons/mdi/plus'
import { Icon, addIcon } from '@iconify/vue'
addIcon('plusIcon', plusIcon)
export default {
  name: 'NewEntry',
  components: {
    w3ppModal,
    w3ppSlider,
    w3ppClose,
    w3ppButton,
    tagsSearch,
    Icon
  },
  props: {
    entryID: String
  },
  data () {
    return {
      date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
      tags: [],
      amount: '',
      open: false
    }
  },
  computed: {
    ledgerRef () {
      return this.$feed.data.ledgers?.[this.$route.params.id]
    }
  },
  watch: {
    ledgerRef: {
      handler (ledger) {
        if (ledger) {
          ledger.ref.collection('entries').doc(this.entryID)
            .onSnapshot(entry => {
              const data = entry.data()
              if (!data) return
              this.date = (new Date(data.date.seconds * 1000)).toLocaleDateString().split('/').reverse().join('-')
              this.amount = data.amount
              this.tags = data.tags
            })
        }
      },
      immediate: true
    }
  },
  methods: {
    openTagsSearch (e) {
      if (e.target.classList.contains('slider') && e.target.classList.contains('viewport'))
        this.$refs.tagSearch.open()
    },
    removeTag (tag) {
      this.tags = this.tags.filter(val => val !== tag)
    },
    updateTags (tags) {
      if (Array.isArray(tags)) this.tags = tags
      else if (!this.tags.includes(tags)) this.tags.push(tags)
    },
    updateEntry () {
      if (!this.date || !this.tags.length || !this.amount)
        return this.$snackBars.show('message', {
          message: 'Data is incomplete',
          action: {
              label: 'Okay',
              fn: () => { this.$snackBars.delete('message') }
          }
        })


      this.open = false
      this.ledgerRef.ref.collection('entries').doc(this.entryID).set({
        date: new Date(this.date),
        tags: this.tags,
        amount: this.amount
      }).then(() => {
        this.$snackBars.show('message', {
          message: 'Entry updated',
          action: {
              label: 'Got it',
              fn: () => { this.$snackBars.delete('message') }
          }
        }, 4)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-footer {
    box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
    padding: 16px;
    text-align: right;
    @media (min-width: 720px) {
      padding: 16px;
    }
  }
  .form {
    box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
    font-size: 18px;
    padding: 16px;
    @media (min-width: 720px) {
      padding: 36px;
    }

    .input-field {
      display: block;
      & + .input-field {
        margin-top: 36px;
      }
      .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      input {
        background: #f6f8f9;
        height: 48px;
        width: 100%;
        -webkit-min-logical-width: calc(100% - 16px);
        padding: 0 8px;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }

    .slider {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      background: #f6f8f9;
      min-height: 48px;

      .tag {
        background: #015fcc;
        color: white;
        padding: 0 2px 0 12px;
        height: 32px;
        display: flex;
        align-items: center;
        border-radius: 16px 8px 8px 16px;

        & + .tag {
          margin-left: 8px;
        }
      }
    }
  }

  .submit {
    background: #015fcc;
    height: 42px;
    padding: 0 16px;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 6px;
  }

  .modal-header {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 16px;
    font-weight: 500;
    @media (min-width: 720px) {
      padding-left: 36px;
    }
  }
</style>
