<template>
  <div class="page">
    <entries-calendar
      :groupedEntries="groupedEntries"
      :selected="selected"
      :month="calendarMonth"
      @selected="select"
      @prev="prevMonth"
      @next="nextMonth"
    />
    <div @click="focusList" class="list doc-actions">
      <w3pp-button
        class="selected-date inline-text"
        @click="setCalendarMonth(selected)"
        color="#3d71f5"
        light
      >
        <div class="date">
          {{format(new Date(selected), 'EE dd/MM')}}
        </div>
      </w3pp-button>
      <new-entry/>
    </div>
    <div class="list entries" ref="list">
      <template v-if="!noResults && !groupedEntries[selected]">
        <div v-for="i in 5" :key="`template${i}`" class="group">
          <div class="group-title">
            <div class="title">
              <div class="day"></div>
              <div class="date"></div>
            </div>
            <div class="total"></div>
          </div>
        </div>
      </template>
      <div
        v-else-if="groupedEntries[selected]"
        :key="selected"
        class="group"
      >
        <div class="group-title">
          <div class="title">
            Total
          </div>
          <div class="total">
            {{ groupedEntries[selected].total }}
          </div>
        </div>
        <div
          :key="entry.id"
          v-for="entry of groupedEntries[selected].entries"
          class="entry"
          @click="toggleTentryActions(entry.id)"
        >
          <w3pp-slider class="tags">
            <div
              :key="entry.id + tag + i"
              v-for="(tag, i) in entry.tags"
              class="tag"
            >{{tag}}</div>
          </w3pp-slider>
          <div class="amount">
            {{ entry.amount }}
          </div>
          <div v-if="entryActions === entry.id" class="actions">
            <edit-entry :entryID="entry.id"/>
            <w3pp-button
              background="#f44336"
              class="remove"
              @click="remove(entry.id)"
            >Remove</w3pp-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  startOfMonth,
  startOfToday,
  format,
  add, sub
} from 'date-fns'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppButton from '@/components/w3ppButton'
import NewEntry from '@/components/ledger/NewEntry'
import EditEntry from '@/components/ledger/EditEntry'
import entriesCalendar from '@/components/ledger/entries/Calendar'
export default {
  name: 'Entries',
  components: {
    w3ppSlider,
    w3ppButton,
    NewEntry,
    EditEntry,
    entriesCalendar,
  },
  data () {
    return {
      initialized: false,
      entries: [],
      selected: format(startOfToday(), 'yyyy/MM/dd'),
      calendarMonth: startOfMonth(startOfToday()),
      noResults: false,
      entryActions: null
    }
  },
  computed: {
    ledgerRef () {
      return this.$feed.data.ledgers?.[this.$route.params.id]
    },
    ledger () {
      return this.ledgerRef?.data()
    },
    groupedEntries () {
      const groupedEntries = this.entries.reduce((entries, entry) => {
        const group = entries[entry.date] || {
          total: 0,
          entries: []
        }
        group.total += Number(entry.amount)
        group.entries.push(entry)
        return {
          ...entries,
          [entry.date]: group
        }
      }, {})
      if (!this.initialized && Object.keys(groupedEntries).length) {
        this.initialized = true
      }
      return groupedEntries
    }
  },
  watch: {
    ledgerRef: {
      handler (ledger) {
        if (ledger) {
          ledger.ref.collection('entries')
            .onSnapshot(entries => {
              this.entries = entries.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                date: format(new Date(doc.data().date.seconds * 1000), 'yyyy/MM/dd')
              }))
            })
        }
      },
      immediate: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.noResults = true
    }, 2000)
  },
  methods: {
    select (date) {
      this.selected = date
      this.setCalendarMonth(date)
      this.focusList()
    },
    focusList () {
      this.$refs['list'].scrollIntoView(true)
    },
    setCalendarMonth (date) {
      this.calendarMonth = startOfMonth(new Date(date))
    },
    prevMonth () {
      this.calendarMonth = sub(this.calendarMonth, { months: 1 })
    },
    nextMonth () {
      this.calendarMonth = add(this.calendarMonth, { months: 1 })
    },
    toggleTentryActions (id) {
      if (this.entryActions === id) this.entryActions = null
      else this.entryActions = id
    },
    remove (entry) {
      const response = confirm("Are you sure you want to remove this item?")
      if (!response) return
      
      this.ledgerRef.ref.collection('entries').doc(entry).delete()
    },
    format
  }
}
</script>
<style lang="scss" scoped>
  .page{
    background: white;
    padding: 16px 0 36px 0;
    
    display: grid;
    grid-template-areas:
      "calendar"
      "header"
      "list";

    @media (min-width: 720px) {
      padding: 16px 16px 36px 16px;
      gap: 16px;
      grid-template-areas:
      "calendar header"
      "calendar list"
      "calendar padding";
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto 1fr;
    }


    .calendar {
      grid-area: calendar;
      padding: 0 16px;
      @media (min-width: 720px) {
        padding: 0;
        width: 32ch;
      }
    }

    .doc-actions {
      @media (max-width: 719px) {
        position: sticky;
        bottom: 0;
        margin-top: 16px;
        padding: 16px;

        border-radius: 8px;
        box-shadow: 0 -1px 2px rgba(100, 121, 143, .2);
        z-index: 1;
      }

      grid-area: header;
      background: white;
      display: flex;
      justify-content: space-between;


      .selected-date {
        .date {
          font-size: .9em;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          small {
            font-size: .8rem;
          }
        }
      }
      &:deep(.button.inline-text) {
        font-weight: 500;
        align-items: center;
        display: inline-flex;
      }
    }
  }


  .entries {
    grid-area: list;
    background: white;
    box-shadow: inset 0 0 0 1px rgba(100, 121, 143, .2);
    border-radius: 6px;
    font-size: 16px;
    overflow: hidden;
    padding: 0;
    margin: 0 16px;
    @media (min-width: 720px) {
      margin: 16px 0 36px 0;
    }
    &:empty {
      padding: 16px;
      &:after {
        content: "Add your first entry of the day"
      }
    }
  }

  .group-title {
    display: grid;
    font-weight: 600;
    grid-template-columns: 1fr auto;
    // box-shadow: inset 0 -1px 0 0 rgba(67, 71, 75, 0.12);
    border-top: 1px solid #e1e4e8;
    align-items: center;
    padding-right: 8px;
    cursor: default;
    height: 52px;
    svg {
      color: grey;
    }
    .title {
      display: flex;
      align-items: baseline;
      padding-left: 16px;
      .day {
        width: 24px;
        &:empty {
          background: #e2e2e2;
          width: 44px;
          height: 22px;
          margin-left: 6px;
        }
      }
      .date {
        margin-left: 16px;
        font-size: 14px;
        &:empty {
          background: #e2e2e2;
          width: 80px;
          height: 22px;
          margin-left: 6px;
        }
      }
    }
  }

  .group:hover {
    .group-title svg {
      color: #77b6fd;
    }
  }
  .entry {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 
      "tags amount" 
      "actions actions";
    align-items: center;
    padding: 0 8px 0 16px;
    min-height: 36px;
    border-top: 1px solid #e1e4e8;
    background: #f6f8fa;
    cursor: pointer;
    &:hover {
      background: #fafafb;
    }
  }

  .tags {
    padding: 0 !important;
    display: flex;
    align-items: center;
    .tag {
      height: 36px;
      display: flex;
      align-items: center;
      border-radius: 8px;

      & + .tag {
        margin-left: 12px;
        &:before {
          content: '|';
          margin-right: 12px;
        }
      }
    }
  }

  .actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 36px;
    box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
    font-size: 14px;

    .edit {
      text-align: center;
      padding: 4px 8px;
      height: 24px;
    }
    .remove {
      height: 24px;
      padding: 4px 8px;
      margin-left: 4px;
      text-align: center;
    }
  }

  .amount, .total {
    display: flex;
    align-items: center;
    height: 36px;
    text-align: right;
    padding-left: 4px;

    &:empty:after {
      content: '';
      background: #e2e2e2;
      width: 48px;
      height: 22px;
      margin-left: 6px;
    }
  }
</style>
