<template>
    <div class="settings">
        <div class="name">
            Shopping List
        </div>
    </div>
</template>
<script>
import pencilIcon from '@iconify-icons/mdi/pencil'
import { Icon, addIcon } from '@iconify/vue'
addIcon('pencilIcon', pencilIcon)
export default {
    components: {
        Icon
    }
}
</script>

<style lang="scss" scoped>
    .settings {
        padding: 16px;
        background: white;
        @media (min-width: 720px) {
            padding: 36px;
        }
    }
</style>