<template>
    <div class="settings">
        <h2 class="uline">Settings</h2>
        <div class="input-label">Ledger name</div>
        <div class="input-row">
            <input
                type="text"
                :value="title"
                @input="title = $event.target.value.trim()"
            >
            <w3pp-button
                class="grey"
                @click="updateName"
                light
                :disabled="!title || title === ledgerRef?.data()?.title"
            >
                Rename
            </w3pp-button>
        </div>
        <section>
            <div class="heading input-row">
                Members
                <w3pp-button
                    @click="inviteMember"
                    class="green"
                >
                    Send Invite
                </w3pp-button>
            </div>
            <div class="members">
                <w3pp-slider
                    v-for="(member, i) of members.filter(_ => _)"
                    :key="member?.email + i"
                    class="member"
                >
                    {{`${member?.displayName} <${member?.email}>`}}
                </w3pp-slider>
                <template v-if="pendingInvites.length">
                    <div class="sub-heading">Pending invites</div>
                    <div
                        class="member"
                        v-for="(email, i) of pendingInvites"
                        :key="email + i"
                    >
                        <w3pp-close class="dark" @click="cancelInvite(email)"/>
                        <w3pp-slider class="member">{{email}}</w3pp-slider>
                    </div>
                </template>
            </div>
        </section>
        <section>
            <div class="heading">Danger Zone</div>
            <div class="danger-items">
                <div class="item">
                    <div v-if="members.filter(_ => _).length > 1" class="content">
                        <div class="title">Leave this ledger</div>
                        Once you leave this ledger, you can access it by invite only. Please be certain.
                    </div>
                    <div v-else class="content">
                        <div class="title">Delete this ledger</div>
                        Once you delete this ledger, there is no going back. Please be certain.
                    </div>
                    <leave-ledger v-if="members.filter(_ => _).length > 1"></leave-ledger>
                    <delete-ledger v-else></delete-ledger>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import firebase, { db } from '@/firebase'
import w3ppButton from '@/components/w3ppButton'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppClose from '@/components/w3ppClose'
import pencilIcon from '@iconify-icons/mdi/pencil'
import { Icon, addIcon } from '@iconify/vue'
import deleteLedger from '@/components/ledger/deleteLedger.vue'
import leaveLedger from '@/components/ledger/leaveLedger.vue'
addIcon('pencilIcon', pencilIcon)
export default {
    name:'Settings',
    components: {
        w3ppButton,
        w3ppSlider,
        Icon,
        w3ppButton,
        deleteLedger,
        leaveLedger,
        w3ppClose
    },
    data () {
        return {
            members: [],
            title: ''
        }
    },
    computed: {
        ledgerRef () {
            return this.$feed.data.ledgers?.[this.$route.params.id]
        },
        pendingInvites () {
            return this.ledgerRef?.data().invites || []
        }
    },
    watch: {
        ledgerRef: {
            handler (ledger) {
                if (ledger) {
                    this.members = []
                    this.ledgerRef?.data().members?.map((userRef, i) => {
                        userRef.onSnapshot(
                            doc => {
                                const {displayName, email} = doc.data()
                                this.members[i] = {displayName, email}
                            },
                            error => console.log('user ref error', error)
                        )
                    })
                    this.title = this.ledgerRef?.data()?.title
                }
            },
            immediate: true
        }
    },
    methods: {
        updateName () {
            const title = this.title
            if (!title) return
            this.ledgerRef.ref.set({ title }, { merge: true })
                .catch(message => this.$snackBars.show('error', { message}))
        },
        inviteMember () {
            var email = prompt("Add an email to invite", '')

            if (!email) return
            email = email.toLowerCase()
            this.ledgerRef.ref.set({
                invites: firebase.firestore.FieldValue.arrayUnion(email)
            }, { merge: true }).catch(message => this.$snackBars.show('error', { message }))
            
            db.collection('invites').doc(email).set({
                invites: firebase.firestore.FieldValue.arrayUnion(this.ledgerRef.ref)
            }, { merge: true }).catch(message => this.$snackBars.show('error', { message }))
        },
        cancelInvite (email) {
            const response = confirm("Are you sure you want to remove this item?")

            if (!response) return
            this.ledgerRef.ref.update({
                invites: firebase.firestore.FieldValue.arrayRemove(email)
            })
            
            db.collection('invites').doc(email).update({
                invites: firebase.firestore.FieldValue.arrayRemove(this.ledgerRef.ref)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .settings {
        padding: 16px;
        background: white;
        @media (min-width: 720px) {
            padding: 36px;
        }

        h2 {
            font-size: 24px;
            font-weight: 400;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
    }

    .uline {
        box-shadow: inset 0 -1px 0 #ebedef;
        margin-bottom: 16px;
    }

    section {
        padding-top: 36px;

        .members {
            box-shadow: inset 0 0 0 1px rgba(100, 121, 143, .2);
            border-radius: 6px;
            font-size: 14px;
        }
        
        .sub-heading, .member {
            padding: 0 8px;
            height: 32px;
            display: flex;
            align-items: center;
            &, & + .sub-heading, & + .member {
                box-shadow: inset 0 1px 0 rgba(100, 121, 143, .12);
            }
        }
        .sub-heading {
            font-weight: 600;
            background: #f4f5f6;
        }
    }

    input {
        height: 32px;
        border-radius: 4px;
        border: none;
        box-shadow: inset 0 0 0 1px #dadce0;
        padding: 0 8px;
        background: #f6f8f9;
        width: 360px;
        flex-shrink: 1;
        font-size: 16px;
    }

    .input-row {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        min-height: 32px;
        align-items: center;
    }
    .input-label {
        line-height: 1.5;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px;
        color: #24292e;
    }
    .heading {
        line-height: 1.5;
        font-size: 14px;
        font-weight: 600;
        color: #24292e;
        padding-bottom: 6px;
    }

    .button {
        font-size: 14px;
        font-weight: 500;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 32px;
        box-shadow: 0 0 0 1px rgba(27, 31, 35, 0.15);
        &.grey {
            background-color: #fafbfc;
            color: #24292e;
        }
        &.green {
            background-color: #2ea44f;
            color: #FFFFFF;
        }
    }

    .danger-items {
        box-shadow: 0 0 0 1px #d73a49;
        border-radius: 6px;
        padding: 16px;
        font-size: 14px;
        .item {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            .title {
                font-weight: 600;
            }
        }
    }
</style>
<style lang="scss" scoped>
.loading-icon {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
</style>
