<template>
  <w3pp-button color="#015fcc" class="trigger inline-text" @click="open"
    ><icon icon="tagMultipleIcon" height="24" /></w3pp-button>

  <w3pp-modal
    :open="!createModal && selectModal"
    @backgroundClick="close"
    @close="close"
    max-width="600px"
    floatingClose
  >
    <template #modal-header>
      <div class="header">
        <div class="search">
          <input
            type="text"
            :value="search"
            @input="search = $event.target.value"
            @keyup.enter="$event.target.blur()"
            placeholder="Search and filter tags"
            autofocus
          />
          <icon icon="magnifyIcon" height="24" />
        </div>
      </div>
    </template>
    <template #default>
      <div class="modal-body">

        <div class="sticky">
          <w3pp-slider v-if="selected.length" class="tags">
            <div
              v-for="tag in selected"
              class="tag"
              :class="{ selected: selected.includes(tag) }"
              :key="`header-tags-${tag}`"
              @click="toggleTag(tag)"
            >
              {{ tag }}
            </div>
          </w3pp-slider>

          <div v-if="suggestions.length" class="results-header">
            Select tags below
            <div class="sort-by">
              <span>Sort by</span>
              <select v-model="sortBy">
                <option value="most-used" selected>Most used</option>
                <option value="alphabetical">Alphabetical</option>
              </select>
            </div>
          </div>
        </div>

        <template v-if="suggestions.length">
          <div class="tags">
            <div
              v-for="tag in suggestions"
              class="tag"
              :class="{ selected: selected.includes(tag) }"
                :key="`body-tags-${tag}`"
              @click="toggleTag(tag)"
            >
              {{ tag }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #modal-footer>
      <div v-if="!search.trim()" class="footer">
        <w3pp-button
            class="createNew"
            background="#f6f8f9"
            color="#1a73e8"
            light
            @click="createModal = true"
        >
            <icon icon="tagPlusIcon" height="24" />
            Create a new tag
        </w3pp-button>
        <w3pp-button
          v-if="tags.sort().join() !== selected.sort().join()"
          @click="done"
          background="#1a73e8"
        >Apply</w3pp-button>
      </div>
      <div v-else class="footer">
        <w3pp-button
          @click="search = ''"
          background="white"
          color="#1a73e8"
          light
          >Go back</w3pp-button
        >
        <w3pp-button v-if="!tagsIncludes(search.trim())" @click="createModal = true" background="#1a73e8">
            <icon icon="tagPlusIcon" height="24" />
            Create a new "{{ search.trim() }}" tag
        </w3pp-button>
        <w3pp-button v-else @click="select" background="#1a73e8">
            <icon icon="tagPlusIcon" height="24" />
            Select "{{ search.trim() }}" tag
        </w3pp-button>
      </div>
    </template>
  </w3pp-modal>

  <w3pp-modal
      :open="createModal"
      @backgroundClick="createModal = false"
      @close="createModal = false"
      max-width="600px"
  >
      <template #modal-header>
      <div class="createModal-header">
          <div class="title">New tag</div>
      </div>
      </template>
      <template #default>
          <div class="createModal-body">
              <div class="helper">
                  Please confirm a new tag name:
              </div>
              <input
                  type="text"
                  :value="search"
                  @input="search = $event.target.value"
                  @keyup.enter="$event.target.blur()"
                  autofocus
              />
          </div>
      </template>
      <template #modal-footer>
      <div class="createModal-footer">
          <w3pp-button
              @click="createModal = false"
              background="white"
              color="#1a73e8"
              class="cancel"
              light
          >Cancel</w3pp-button
          >
          <w3pp-button
              @click="create"
              background="#1a73e8"
              :disabled="!search.trim() || tagsIncludes(search.trim())"
          >Create</w3pp-button>
      </div>
      </template>
  </w3pp-modal>
</template>
<script>
import firebase from '@/firebase'
import w3ppModal from "@/components/w3ppModal";
import w3ppButton from "@/components/w3ppButton";
import w3ppClose from "@/components/w3ppClose";
import w3ppSlider from '@/components/w3ppSlider'
import plusIcon from "@iconify-icons/mdi/plus";
import magnifyIcon from "@iconify-icons/mdi/magnify";
import tagMultipleIcon from '@iconify-icons/mdi/tag-multiple'
import tagPlusIcon from '@iconify-icons/mdi/tag-plus';
import { Icon, addIcon } from "@iconify/vue";
addIcon("plusIcon", plusIcon);
addIcon("magnifyIcon", magnifyIcon);
addIcon("tagMultipleIcon", tagMultipleIcon);
addIcon("tagPlusIcon", tagPlusIcon);
export default {
  name: "TagsSearch",
  components: {
    w3ppModal,
    w3ppButton,
    w3ppClose,
    w3ppSlider,
    Icon,
  },
  props: {
    tags: Array,
    ledgerID: String,
  },
  data() {
    return {
      sortBy: "most-used",
      selectModal: false,
      createModal: false,
      search: "",
      results: 8,
      allTags: [],
      selected: [],
      popular: [
        "Groceries",
        "Rent",
        "Petrol",
        "Uber",
        "Drinks",
        "Apps",
        "Transport",
        "House essentials",
        "Child care",
        "Education",
        "Clothing",
        "Eating out",
        "Black tax",
        "Order in",
        "Date night",
        "Debt orders",
      ],
    };
  },
  computed: {
    tagsIndex() {
      let tagsIndex = Object.fromEntries(
        this.tags.map((tag) => [tag, { occurrence: 0 }])
      );
      return this.allTags.reduce((index, tags) => {
        tags.forEach((tag) => {
          if (!this.tagsIncludes(tag, index)) {
            index[tag] = { occurrence: 1 };
          } else {
            index[tag].occurrence++;
          }
        });
        return index;
      }, tagsIndex);
    },
    suggestions() {
      function adjustedTag(tag) {
        return tag
          .replace(/[^\p{L}\p{N}\p{P}\p{Z}\n]/gu, "")
          .trim()
          .toLowerCase();
      }
      let suggestions = Object.keys(this.tagsIndex);

      if (this.sortBy == "most-used") {
        suggestions = suggestions.sort((a, b) => {
          const _a = this.tagsIndex[a];
          const _b = this.tagsIndex[b];
          if (_a.occurrence < _b.occurrence) return 1;
          if (_a.occurrence > _b.occurrence) return -1;
          return 0;
        });
      } else {
        suggestions = suggestions.sort((a, b) => {
          if (adjustedTag(a) > adjustedTag(b)) return 1;
          if (adjustedTag(a) < adjustedTag(b)) return -1;
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });
      }

      const search = this.search.trim();
      if (search)
        return suggestions.filter((tag) =>
          adjustedTag(tag).includes(adjustedTag(search))
        );
      return suggestions;
    },
    ledgerRef() {
      return this.$feed.data.ledgers?.[this.ledgerID];
    },
  },
  watch: {
    selectModal() {
      this.selected = this.tags;
    },
    ledgerRef: {
      handler(ledger) {
        if (ledger) {
          ledger.ref.collection("entries").onSnapshot((entries) => {
            this.allTags = [ledger.data().tags ?? [], ...entries.docs.map((doc) => doc.data().tags)]
          });
        }
      },
      immediate: true,
    },
  },
  emits: ["selectTag"],
  methods: {
    selectedIncludes(tag) {
      return this.selected
        .map((key) => key.toLowerCase())
        .includes(tag.toLowerCase());
    },
    tagsIncludes(tag, tags = this.tagsIndex) {
      return Object.keys(tags).includes(tag);
    },
    toggleTag(tag) {
      if (!this.selected.includes(tag)) {
        this.selected = [...this.selected, tag]
        this.search = ''
      } else this.selected = this.selected.filter((val) => val !== tag);
    },
    select() {
      this.search = this.search.trim();
      if (this.search && !this.selected.includes(this.search)) this.selected.push(this.search);
      this.search = "";
    },
    create () {
        this.ledgerRef.ref.set({
            tags: firebase.firestore.FieldValue.arrayUnion(this.search)
        }, { merge: true }).catch(message => this.$snackBars.show('error', { message }))
        this.createModal = false
        this.toggleTag(this.search)
    },
    done() {
      this.$emit("selectTag", this.selected);
      this.close();
    },
    open() {
      this.selectModal = true;
    },
    close() {
      if (this.search) {
        this.search = "";
      } else this.selectModal = false
    },
  },
};
</script>
<style lang="scss" scoped>
.trigger {
  font-size: 18px;
}
input[type="text"] {
  height: 32px;
  border-radius: 4px;
  border: none;
  box-shadow: inset 0 0 0 1px #dadce0;
  padding: 0 8px;
  background: #f6f8f9;
  font-size: 16px;
  width: 100%;
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}

.helper {
  font-size: 14px;
  padding: 4px 0;
}

.popular {
  margin-top: 32px;
}
.header {
  padding: 12px 12px 12px 16px;
  .search {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 36px;
    max-width: 360px;
    svg {
      pointer-events: none;
      position: absolute;
      right: 2px;
      opacity: .5;
    }
  }
}
.results-header {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  .sort-by {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

:disabled {
  pointer-events: none;
}

.modal-body {
  padding: 0 16px 16px 16px;
}

.sticky {
  position: sticky;
  top: 0;
  background: #ffffff;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  &, &:deep(.container) {
    gap: 8px;
  }

  &:not(:empty) {
    padding-bottom: 16px;
  }

  .tag {
    background: #eaeced;
    padding: 0 8px;
    height: 28px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    box-shadow: rgba(100, 121, 143, .12) 0 0 0 1px inset;

    &:hover {
      // box-shadow: inset 0 0 0 1px #1a5cff;
      background: #e0e2e3;
    }

    &.selected {
      background: #2196f3;
      color: white;
    }
  }
}
.footer {
  text-align: right;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);

  .button {
      min-height: 38px;
      font-weight: 500;
      font-size: 16px;
      flex-grow: 1;
  }
}

.createNew {
  box-shadow: inset 0 0 0 1px #dadce0;
}

.createModal-header {
  padding: 12px 16px;
  .title {
    font-weight: 600;
    font-size: 24px;
  }
}
.createModal-body {
    padding: 0 16px 2px 16px;
}
.createModal-footer {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    // box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);

    .button {
        min-height: 38px;
        font-weight: 500;
        font-size: 16px;
    }
}

</style>
