<template>
    <div class="calendar">
        <div class="month">
            <w3pp-button
                background="#e0e3eb"
                color="#677e9e"
                light
                icon
                @click="$emit('prev')"
            ><icon
                icon="menuLeftIcon"
                height="20"
            /></w3pp-button>
            {{months[getMonth(month)]}} {{getYear(month)}}
            <w3pp-button
                background="#e0e3eb"
                color="#677e9e"
                light
                icon
                @click="$emit('next')"
            ><icon
                icon="menuRightIcon"
                height="20"
            /></w3pp-button>
        </div>
        <div class="week-days">
            <abbr title="Sunday">Sun</abbr>
            <abbr title="Monday">Mon</abbr>
            <abbr title="Tuesday">Tue</abbr>
            <abbr title="Wednesday">Wed</abbr>
            <abbr title="Thursday">Thu</abbr>
            <abbr title="Friday">Fri</abbr>
            <abbr title="Saturday">Sat</abbr>
        </div>
        <div class="date-grid">
            <div
                v-for="date in dates"
                :key="date.date"
                class="date"
                :class="{
                    sameMonth: isSameMonth(new Date(date.date), month),
                    today: startOfDay(new Date(date.date)).toString() === startOfToday().toString(),
                    selected: date.date === selected
                }"
                @click="$emit('selected', date.date)"
            >
                <div class="day">{{date.day}}</div>
                <w3pp-slider class="total">{{groupedEntries?.[date.date]?.total || '-'}}</w3pp-slider>
            </div>
        </div>
    </div>
</template>
<script>
import {
    startOfDay,
    startOfToday,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    eachDayOfInterval,
    format,
    getDate,
    isSameMonth,
    getMonth,
    getYear
} from 'date-fns'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppButton from '@/components/w3ppButton'
import menuRightIcon from '@iconify-icons/mdi/chevron-right'
import menuLeftIcon from '@iconify-icons/mdi/chevron-left'
import { Icon, addIcon } from '@iconify/vue'
addIcon('menuLeftIcon', menuLeftIcon)
addIcon('menuRightIcon', menuRightIcon)

export default {
    name: 'Calendar',
    components: {
        w3ppButton,
        w3ppSlider,
        Icon
    },
    props: {
        groupedEntries: Object,
        selected: String,
        month: Date
    },
    data () {
        return {
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
        }
    },
    computed: {
        dates () {
            return eachDayOfInterval({
                start: startOfWeek(this.month),
                end: endOfWeek(endOfMonth(this.month)),
            }).map(date => ({
                date: format(new Date(date), 'yyyy/MM/dd'),
                day: getDate(date)
            }))
        }
    },
    emits: ['selected', 'prev', 'next'],
    methods: {
        isSameMonth,
        startOfToday,
        startOfDay,
        getMonth,
        getYear,
    }
}
</script>
<style lang="scss" scoped>
    .calendar {
        .month {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            font-size: 18px;
            font-weight: 500;
        }
        .week-days, .date-grid {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 1px;
        }
        .date-grid {
            background: #b6c4d2;
        }
        .week-days {
            abbr {
                text-align: center;
                font-weight: bold;
                padding: 16px 0;
                font-size: 14px;
            }
        }
        .date-grid {
            background: #f5f8fa;
            box-shadow: 0 0 0px 1px rgb(224, 228, 232);
            border-radius: 6px;
            .date {
                min-height: 52px;
                display: flex;
                flex-direction: column;
                overflow: auto;
                padding: 4px 1px;
                background: #f3f6f8;
                cursor: pointer;
                border-radius: 6px;
                .day {
                    color: #acacac;
                    font-size: 13px;
                    font-weight: bold;
                    align-self: flex-end;
                    // margin: 8px;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }
                &.sameMonth {
                    background: #ffffff;
                    .day {
                        color: #48586f;
                        background: #fcfdfd;
                    }
                }

                &:hover {
                    box-shadow: 0 0 0 2px #bde7f4;
                    z-index: 0;
                }
                &.selected {
                    box-shadow: 0 0 0 2px #1babd8;
                    z-index: 1;
                    .day {
                        background: #daebfb;
                        color: #004a94;
                    }
                }
                &.today {
                    .day {
                        background: #3d71f5;
                        color: white;
                    }
                }
                .total {
                    color: #777;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 500;
                    align-self: center;
                    padding: 0 4px;
                    &:deep(.container) {
                        display: block;
                        padding: 4px 0;
                        text-align: right;
                    }
                }
            }
        }
    }
</style>