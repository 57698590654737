<template>
  <w3pp-button
    class="danger"
    @click="open = true"
    light
  >Leave this ledger</w3pp-button>

  <w3pp-modal
    :open="open"
    floatingClose
    @backgroundClick="open = false"
    @close="open = false"
  >
    <template #modal-header>
      <div class="modal-header">
        Are you absolutely sure?
      </div>
    </template>
    <template #default>
      <div class="modal-body">
        <div class="warning">
          Unexpected bad things will happen if you don’t read this!
        </div>
        <p>
          This action cannot be undone. This will permanently remove you from the <b>{{ledgerRef?.data()?.title}}</b> ledger. You can only access it again if a current member invites you.
        </p>
        <label>
          <input type="checkbox" v-model="understood">
          I understand the consequences
        </label>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-footer">
        <w3pp-button
          class="danger block"
          light
          :disabled="!understood"
          @click="leaveLedger"
        >Leave this ledger</w3pp-button>
      </div>
    </template>
  </w3pp-modal>

</template>
<script>
import firebase, { auth, db } from '@/firebase'
import w3ppModal from '@/components/w3ppModal'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppClose from '@/components/w3ppClose'
import w3ppButton from '@/components/w3ppButton'
import tagsSearch from '@/components/tagsSearch'
import plusIcon from '@iconify-icons/mdi/plus'
import { Icon, addIcon } from '@iconify/vue'
addIcon('plusIcon', plusIcon)
export default {
  name: 'LeaveLedger',
  components: {
    w3ppModal,
    w3ppSlider,
    w3ppClose,
    w3ppButton,
    tagsSearch,
    Icon
  },
  data () {
    return {
      open: false,
      understood: false
    }
  },
  computed: {
    ledgerRef () {
      return this.$feed.data.ledgers?.[this.$route.params.id]
    }
  },
  methods: {
    leaveLedger () {
      const ledgerRef = this.ledgerRef
      const userRef = db.collection('users').doc(auth.currentUser.uid)
      this.$router.push('/')
      Promise.all([
        ledgerRef.ref.update({
          members: firebase.firestore.FieldValue.arrayRemove(userRef)
        }),
        userRef.update({
          ledgers: firebase.firestore.FieldValue.arrayRemove(ledgerRef.ref)
        })
      ])
      .then(() => {
        this.$snackBars.show('removed', {
          message: `You have successfully exited.`,
          action: {
            label: 'Got it',
            fn: () => { this.$snackBars.delete('removed') }
          }
        })
      })
      .catch(error => {
        console.log({ error })
        console.log({ ledgerRef })
      })

      // this.open = false

      // this.$router.push('/')

      // this.ledgerRef.ref.collection('entries').add({
      //   date: new Date(this.date),
      //   tags: this.tags,
      //   amount: this.amount
      // }).then(() => {
      //   this.date = new Date().toJSON().slice(0,10).replace(/-/g,'-')
      //   this.tags = []
      //   this.amount = ''
      //   this.$snackBars.show('message', {
      //     message: 'New entry added',
      //     action: {
      //         label: '👍🏾',
      //         fn: () => { this.$snackBars.delete('message') }
      //     }
      //   })
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-footer {
    box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
    padding: 16px;
    text-align: right;
    @media (min-width: 720px) {
      padding: 16px;
    }
  }
  
  .modal-body {
    padding-bottom: 16px;
    .warning {
      padding: 16px;
      background: #fffbdd;
      color: black;
      border-top: 1px solid rgba(176, 136, 0, .2);
      border-bottom: 1px solid rgba(176, 136, 0, .2);
    }
    p {
      padding: 16px;
    }
    label {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 0 16px;
    }
  }

  .modal-header {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 16px;
    font-weight: 500;
    @media (min-width: 720px) {
      padding-left: 36px;
    }
  }
  .button {
      font-size: 14px;
      font-weight: 500;
      padding-top: 4px;
      padding-bottom: 4px;
      align-items: center;
      display: flex;
      flex-direction: row;
      min-height: 32px;
      box-shadow: 0 0 0 1px rgba(27, 31, 35, 0.15);
      // flex: 0;
      flex-shrink: 0;
      align-self: flex-start;
      &.danger {
          background-color: #fafbfc;
          color: #b00011;
      }
  }
</style>
