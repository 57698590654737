<template>
  <w3pp-button
    class="green"
    @click="open = true"
    light
  >
    Add entry
  </w3pp-button>

  <w3pp-modal
    :open="open"
    floatingClose
    @backgroundClick="open = false"
    @close="open = false"
  >
    <template #modal-header>
      <div class="modal-header">
        Add new entry
      </div>
    </template>
    <template #default>
      <div class="form">
        <label class="input-field">
          <div class="label">Date *</div>
          <input type="date" name="date" v-model="date">
        </label>
        <label class="input-field">
          <div class="label">Amount *</div>
          <input type="number" v-model="amount" name="amount">
        </label>
        <div class="input-field">
          <div class="label">
            Add tags *
            <tagsSearch ref="tagSearch" @selectTag="updateTags" :tags="tags" :ledgerID="$route.params.id" />
          </div>
          <w3pp-slider class="slider" @click="openTagsSearch">
            <div
              v-for="(tag, i) in tags"
              :key="i"
              class="tag"
            >{{tag}}<w3ppClose @click="removeTag(tag)"/></div>
          </w3pp-slider>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-footer">
        <w3pp-button class="submit" @click="addEntry" background="#1a73e8">Submit</w3pp-button>
      </div>
    </template>
  </w3pp-modal>

</template>
<script>
import w3ppModal from '@/components/w3ppModal'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppClose from '@/components/w3ppClose'
import w3ppButton from '@/components/w3ppButton'
import tagsSearch from '@/components/tagsSearch'
import plusIcon from '@iconify-icons/mdi/plus'
import { Icon, addIcon } from '@iconify/vue'
addIcon('plusIcon', plusIcon)
export default {
  name: 'NewEntry',
  components: {
    w3ppModal,
    w3ppSlider,
    w3ppClose,
    w3ppButton,
    tagsSearch,
    Icon
  },
  data () {
    return {
      date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
      tags: [],
      amount: '',
      open: false
    }
  },
  computed: {
    ledgerRef () {
      return this.$feed.data.ledgers?.[this.$route.params.id]
    }
  },
  methods: {
    openTagsSearch (e) {
      if (e.target.classList.contains('slider') && e.target.classList.contains('viewport'))
        this.$refs.tagSearch.open()
    },
    removeTag (tag) {
      this.tags = this.tags.filter(val => val !== tag)
    },
    updateTags (tags) {
      if (Array.isArray(tags)) this.tags = tags
      else if (!this.tags.includes(tags)) this.tags.push(tags)
    },
    addEntry () {
      if (!this.date || !this.tags.length || !this.amount)
        return this.$snackBars.show('message', {
          message: 'Data is incomplete',
          action: {
              label: 'Okay',
              fn: () => { this.$snackBars.delete('message') }
          }
        })

      this.open = false

      this.ledgerRef.ref.collection('entries').add({
        date: new Date(this.date),
        tags: this.tags,
        amount: this.amount
      }).then(() => {
        this.date = new Date().toJSON().slice(0,10).replace(/-/g,'-')
        this.tags = []
        this.amount = ''
        this.$snackBars.show('message', {
          message: 'New entry added',
          action: {
              label: 'Okay',
              fn: () => { this.$snackBars.delete('message') }
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-footer {
    box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
    padding: 16px;
    text-align: right;
    @media (min-width: 720px) {
      padding: 16px;
    }
  }
  .form {
    box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
    font-size: 16px;
    padding: 16px;
    @media (min-width: 720px) {
      padding: 36px;
    }

    .input-field {
      display: block;
      & + .input-field {
        margin-top: 24px;
      }
      .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 4px;
      }

      input {
        background: #f6f8f9;
        height: 36px;
        width: 100%;
        -webkit-min-logical-width: calc(100% - 16px);
        padding: 0 8px;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        display: flex;
        align-items: center;
        box-shadow: inset 0 0 0 1px #dadce0;
      }
    }

    .slider {
      display: flex;
      align-items: center;
      padding: 0 4px;
      border-radius: 8px;
      background: #f6f8f9;
      min-height: 36px;
      box-shadow: inset 0 0 0 1px #dadce0;

      .tag {
        background: #2196f3;
        color: white;
        padding-left: 8px;
        // height: 32px;
        display: flex;
        align-items: center;
        border-radius: 6px;

        & + .tag {
          margin-left: 8px;
        }
      }
    }
  }

  .submit {
    height: 36px;
    padding: 0 16px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 6px;
  }

  .modal-header {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 16px;
    font-weight: 500;
    @media (min-width: 720px) {
      padding-left: 36px;
    }
  }

  .button.green {
      font-size: 14px;
      font-weight: 500;
      padding-top: 0;
      padding-bottom: 0;
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 32px;
      box-shadow: 0 0 0 1px rgba(27, 31, 35, 0.15);
      background-color: #2ea44f;
      color: #FFFFFF;
  }
</style>
