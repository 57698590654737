<template>
  <div class="overview">
    <div class="cards">
      <section class="lastWeek">
        <div class="title">
          Last 7 days 💵
          <div class="range">
            {{ last7days.days[0] }} - {{ last7days.days[last7days.days.length - 1] }}
          </div>
        </div>
        <div class="figures">
          <div class="figure">
            <div class="label">Total</div>
            <div class="value">{{ last7days.total }}</div>
          </div>
          <div class="figure">
            <div class="label">Average/day</div>
            <div class="value">{{ last7days.average }}</div>
          </div>
        </div>
      </section>
      <section class="lastMonth">
        <div class="title">
          Last month 📆
          <div class="range">
            {{ lastMonth.days[0] }} - {{ lastMonth.days[lastMonth.days.length - 1] }}
          </div>
        </div>
        <div class="figures">
          <div class="figure">
            <div class="label">Total</div>
            <div class="value">{{ lastMonth.total }}</div>
          </div>
          <div class="figure">
            <div class="label">Average/day</div>
            <div class="value">{{ lastMonth.average }}</div>
          </div>
        </div>
      </section>
    </div>
    <h3>Tag stats</h3>
    <div class="section">
        <div class="cards">
            <section class="standOut">
                <div class="title">Stand out tags ⚡️</div>

                <div class="figures">
                    <div class="figure" v-for="[tag, val] in tagAmounts" :key="'amounts-' + tag">
                        <div class="label">{{tag}}</div>
                        <div class="value">{{val}}</div>
                    </div>
                </div>
            </section>
            <section class="popular">
                <div class="title">Most occuring tags 🔥</div>

                <div class="figures">
                    <div class="figure" v-for="[tag, val] in tagOccurances" :key="'occurances-' + tag">
                        <div class="label">{{tag}}</div>
                        <div class="value">{{val.occurances}}</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  </div>
</template>

<script>
import { sub, add, format, eachDayOfInterval } from "date-fns"
import NewEntry from "@/components/ledger/NewEntry"
import EditEntry from "@/components/ledger/EditEntry"
import w3ppButton from "@/components/w3ppButton"
import w3ppSlider from "@/components/w3ppSlider"
import menuDownIcon from "@iconify-icons/mdi/menu-down"
import menuRightIcon from "@iconify-icons/mdi/menu-right"
import { Icon, addIcon } from "@iconify/vue"
addIcon("menuDownIcon", menuDownIcon)
addIcon("menuRightIcon", menuRightIcon)
export default {
    name: "Overview",
    components: {
        w3ppButton,
        w3ppSlider,
        NewEntry,
        EditEntry,
        Icon,
    },
    data() {
        return {
            entries: [],
        }
    },
    computed: {
        ledgerRef() {
            return this.$feed.data.ledgers?.[this.$route.params.id]
        },
        ledger() {
            return this.ledgerRef?.data()
        },
        tags () {
            const _tags = {}
            this.entries.forEach(entry => {
                const { amount, tags } = entry
                tags.forEach(tag => {
                    if (!_tags[tag]) _tags[tag] = {
                        amount: 0,
                        occurances: 0
                    }

                    _tags[tag].amount += Number(amount)
                    _tags[tag].occurances++
                })
            })

            return _tags
        },
        tagAmounts () {
            return Object.entries(this.entries.reduce((acc, entry) => {
                const tag = [...entry.tags].sort((a, b) => {
                    if (this.tags[a].amount > this.tags[b].amount) return 1
                    if (this.tags[a].amount < this.tags[b].amount) return -1
                    return 0
                }).pop()

                if (!acc[tag]) acc[tag] = 0

                acc[tag] += Number(entry.amount)
                return acc
            }, {})).sort((a, b) => {
                if (a[1] < b[1]) return 1
                if (a[1] > b[1]) return -1
                return 0
            }).slice(0, 10)
        },
        tagOccurances () {
            return Object.entries(this.tags).sort((a, b) => {
                if (a[1].occurances < b[1].occurances) return 1
                if (a[1].occurances > b[1].occurances) return -1
                return 0
            }).slice(0, 10)
        },
        groupedEntries() {
            const groupedEntries = this.entries.reduce((entries, entry) => {
                const date = d(entry.date);
                const group = entries[date] || {
                    total: 0,
                    entries: [],
                }
                group.total += Number(entry.amount)
                group.entries.push(entry)
                return {
                    ...entries,
                    [date]: group,
                }
            }, {})
            return groupedEntries
        },
        last7days() {
            const days = this.daysFrom(format(sub(new Date, { weeks: 1 }), 'yyyy/MM/dd'))
            const total = days
                .map((date) => this.groupedEntries[date]?.total || 0)
                .reduce((acc, curr) => acc + curr, 0);
            return {
                days,
                total,
                average: Math.round(total / 7),
            }
        },
        lastMonth () {
            const days = this.daysFrom(format(sub(new Date, { months: 1 }), 'yyyy/MM/dd'))
            const total = days
                .map((date) => this.groupedEntries[date]?.total || 0)
                .reduce((acc, curr) => acc + curr, 0);
            return {
                days,
                total,
                average: Math.round(total / days.length),
            }
        },
        today () {
            return format(new Date, 'yyyy/MM/dd')
        }
    },
    watch: {
        ledgerRef: {
        handler(ledger) {
            if (ledger) {
            ledger.ref.collection("entries").onSnapshot((entries) => {
                this.entries = entries.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    date: format(new Date(doc.data().date.seconds * 1000), 'yyyy/MM/dd')
                }))
            });
            }
        },
        immediate: true,
        },
    },
    methods: {
        daysFrom (from) {
            return eachDayOfInterval({
              start: new Date(from),
              end: sub(new Date(this.today), { days: 1 }),
            }).map(date => format(new Date(date), 'yyyy/MM/dd'))
        }
    }
}
</script>

<style lang="scss" scoped>
.lastWeek {
  background: #fff69c;
}
.lastMonth {
  background: #c5e0a6;
}
.standOut {
  background: #fdeaed; 
}
.popular {
  background: #b2ebf2; 
}

.overview {
  padding: 16px;
  background: white;
  @media (min-width: 720px) {
    padding: 36px;
  }
}
.cards {
  columns: 250px 2;
  column-gap: 16px;
  @media (min-width: 720px) {
    column-gap: 36px;
  }
}

h3 {
  height: 42px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, .2);
}

section {
  width: 100%;
  background: white;
  border-radius: 6px;
  break-inside: avoid-column;
  margin-bottom: 16px;
  @media (min-width: 720px) {
    margin-bottom: 36px;
  }
  box-shadow: inset 0 0 0 1px rgba(100, 121, 143, .2);

  .title {
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    min-height: 52px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, .2);
    .range {
      font-size: 14px;
    }
  }
  .figures {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 16px;
    .figure {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      & + .figure {
        box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, .12);
      }
    }
  }
}
</style>